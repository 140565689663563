@font-face {
  font-family: "SF-Compact-Display-Light";
  src: url('SF-Compact-Display-Light.ttf') format("truetype");
}
@font-face {
  font-family: "SF-Compact-Display-Medium";
  src: url('SF-Compact-Display-Medium.ttf') format("truetype");
}
@font-face {
  font-family: "SF-Compact-Display-Regular";
  src: url('SF-Compact-Display-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "SF-Compact-Display-Bold";
  src: url('SF-Compact-Display-Bold.ttf') format("truetype");
}
@font-face {
  font-family: "SF-Compact-Rounded-Semibold";
  src: url('SF-Compact-Rounded-Semibold.ttf') format("truetype");
}
@font-face {
  font-family: "Rockwell-Regular-Font";
  src: url('Rockwell-Regular-Font.ttf') format("truetype");
}
body {
  margin: 0 auto;
  font-size: 16px;
}
body .swal2-container {
  z-index: 9999;
  font-family: "SF-Compact-Display-Regular";
  width: 100%;
  padding: 0em;
}
body .swal2-container .swal2-toast {
  background-color: #2B6A46 !important;
  height: auto;
  width: 100%;
}
body .swal2-container .swal2-toast .swal2-title {
  font-size: 1rem;
  color: #F6FBF4;
  font-family: "SF-Compact-Display-Bold";
}
body .swal2-container .swal2-toast .swal2-timer-progress-bar {
  background: #F6FBF4;
}
body .swal2-modal {
  margin-left: 0.625em !important;
  margin-right: 0.625em !important;
}
body .swal2-modal .swal2-popup.swal2-toast {
  width: 100% !important;
  background: transparent;
  box-shadow: none !important;
}
body .swal2-modal .swal2-styled.swal2-confirm {
  background-color: #2B6A46;
  outline: none;
}
body .swal2-content {
  font-size: 1.3em;
}
.mandatory-field {
  color: red !important;
}
.form-div {
  font-size: 1rem;
  position: relative;
  height: 48px;
  margin-inline: 1px;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.form-div a {
  color: #00341b;
}
.form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 1.1875em);
  height: 0.75em;
  font-size: 1rem;
  border: 1px solid #00341b;
  border-radius: 0.5em;
  outline: none;
  padding: 0.9625em 0.3em 1.1875em 0.8125em;
  background: none;
  z-index: 1;
  color: #00341b;
}
.form-input:disabled {
  background: #f3f1f1;
  cursor: not-allowed;
}
.form-textarea {
  position: relative;
  top: 0;
  left: 0;
  width: calc(100% - 1.1875em);
  /* height: 0.75em; */
  font-size: 1rem;
  border: 1px solid #3d77c5;
  border-radius: 0.5em;
  outline: none;
  padding: 0.9625em 0.3em 1.1875em 0.8125em;
  background: none;
  z-index: 1;
  color: #3d77c5;
  font-family: "SF-Compact-Display-Regular";
}
.text-area-input {
  position: relative;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.text-area-input .form-textarea {
  position: relative;
  top: 0;
  left: 0;
  width: calc(100% - 1.1875em);
  /* height: 0.75em; */
  font-size: 1rem;
  border: 1px solid #3d77c5;
  border-radius: 0.5em;
  outline: none;
  padding: 0.9625em 0.3em 1.1875em 0.8125em;
  background: none;
  z-index: 1;
  color: #3d77c5;
  font-family: "SF-Compact-Display-Regular";
}
::placeholder {
  font-family: "SF-Compact-Display-Regular";
  color: #c5c4c4;
}
/*Input focus move up label*/
.form-input:focus + .form-label {
  top: -0.5em;
  left: 1em;
  font-size: 0.75rem;
  z-index: 10;
  font-family: "SF-Compact-Display-Bold";
}
/*Input focus sticky top label*/
.form-input:not(:placeholder-shown).form-textarea:not(:focus) + .form-label {
  top: -0.5em;
  left: 0.8em;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: "SF-Compact-Display-Bold";
  z-index: 10;
  color: red;
}
.form-label {
  position: absolute;
  left: 1em;
  top: 1em;
  padding: 0 0.25em;
  background-color: #F6FBF4;
  color: #00341b;
  font-size: 1rem;
  transition: 0.3s;
  text-transform: capitalize;
}
input:focus::placeholder {
  color: transparent;
}
textarea:focus::placeholder {
  color: transparent;
}
.form-label-placeholder {
  top: -0.5em;
  left: 0.8em;
  font-size: 0.75rem;
  font-family: "SF-Compact-Display-Bold";
  z-index: 10;
}
hr {
  border: none;
  height: 2px;
  background: #00341b;
}
.hint__circle {
  display: inline-block;
  height: 18px;
  width: 18px;
  /* margin: 0 auto; */
  border-radius: 50%;
  color: #3d77c5;
  text-align: center;
  line-height: 18px;
  font-family: arial;
  font-size: 19px;
  border: 2px solid #3d77c5;
  opacity: 0.7;
  font-family: "SF-Compact-Display-Medium";
  background-color: aliceblue;
}
.hint__circle:hover {
  cursor: pointer;
  opacity: 1;
}
.form-validation-errors {
  color: #ba1010;
  margin: 0.3125em;
  font-size: 0.75rem;
  font-family: "SF-Compact-Display-Regular";
}
.icon-background {
  background-color: #00341b;
}
.btn-primary-nowidth {
  display: block;
  padding: 0.75rem 2rem;
  outline: none;
  border: none;
  background-color: #2B6A46;
  color: #F6FBF4;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.btn-primary-nowidth:hover {
  box-shadow: 10px 16px 36px rgba(17, 17, 17, 0.15);
  transform: scale(1.015);
}
.btn-primary-nowidth:active {
  background-color: #f3bb9f;
}
.btn-primary-nowidth:disabled {
  cursor: not-allowed;
  background-color: #f6a981;
}
.btn-primary {
  display: block;
  padding: 0.75rem 2rem;
  outline: none;
  border: none;
  background-color: #2B6A46;
  color: #F6FBF4;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.btn-primary:hover {
  box-shadow: 10px 16px 36px rgba(17, 17, 17, 0.15);
  transform: scale(1.015);
}
.btn-primary:active {
  background-color: #f3bb9f;
}
.btn-primary:disabled {
  cursor: not-allowed;
  background-color: #f6a981;
}
.btn-default {
  display: block;
  padding: 0.75rem 2rem;
  outline: none;
  border: none;
  background-color: #F6FBF4;
  color: #2B6A46;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.btn-with-border {
  display: block;
  padding: 0.75rem 2rem;
  outline: none;
  border: 0.2em solid #2B6A46;
  color: #2B6A46;
  background-color: #F6FBF4;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  font-weight: bold;
  margin: 1em 0;
}
.btn-with-border:hover {
  box-shadow: 10px 16px 36px rgba(17, 17, 17, 0.15);
  transform: scale(1.015);
}
.btn-with-border :active {
  background-color: #f3bb9f;
  color: #fff;
}
.btn-with-border:disabled {
  cursor: not-allowed;
  color: #f6a981;
  border: 0.2em solid #f6a981;
}
.back-btn {
  font-size: 1rem;
  padding: 0.75rem 2rem;
  text-align: center;
  background-color: #fff;
  color: #ff7937;
  transition: 0.3s;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.back-btn span {
  cursor: pointer;
}
.form-div .div-mapbox .form-label-placeholder {
  z-index: 12;
}
.form-div .div-mapbox .mapboxgl-ctrl-geocoder {
  box-shadow: none;
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  height: 2.75em;
  font-size: 1rem;
  border: 1px solid #3d77c5;
  border-radius: 0.5em;
  outline: none;
  background: none;
  color: #3d77c5;
}
.form-div .div-mapbox .mapboxgl-ctrl-geocoder--input {
  padding-left: 0.5rem;
  color: #3d77c5;
  font-size: 1rem;
}
.form-div .div-mapbox .mapboxgl-ctrl-geocoder--icon-search {
  display: none;
}
/* Media queries*/
@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: 100%;
  }
}
.autocomplete-container .input-container input {
  top: 0;
  left: 0;
  width: calc(100% - 1.1875em) !important;
  height: 0.75em !important;
  font-size: 1rem !important;
  border: 1px solid #3d77c5 !important;
  border-radius: 0.5em;
  outline: none;
  padding: 0.9625em 0.3em 1.1875em 0.8125em !important;
  background: none;
  z-index: 1;
  color: #3d77c5 !important;
  box-sizing: content-box !important;
}
video {
  width: 100%;
}
.main-heading {
  font-size: 2.5rem;
  color: #00341b;
  font-family: "SF-Compact-Display-Medium";
  margin-bottom: 1em;
  cursor: default;
}
.parent-component .remote-media video {
  height: 100%;
  object-fit: cover;
}
.parent-component .remote-media .local-media video {
  height: 160px;
}
.main-component .remote-media video {
  height: 100%;
  object-fit: contain;
}
.main-component .remote-media video:nth-child(3) {
  object-fit: cover;
}
.main-component .remote-media video:nth-child(2) {
  object-fit: cover;
}
.main-component .remote-media video:nth-child(1) {
  object-fit: cover;
}
.main-component .remote-media video:nth-child(4) {
  margin-top: -76em;
  object-fit: contain;
  position: relative;
  padding-top: 0% !important;
  min-height: 100vh;
  background-color: black;
  top: -1em;
}
input[type=text]:read-only,
input[type=number]:read-only,
input[type=checkbox]:disabled {
  background-color: #ededed;
  cursor: default;
}
.myDpSelectorArrow .myDpMonthYearSelBar {
  align-items: center;
}
.overlay {
  z-index: 999999 !important;
}
@media only screen and (max-width: 500px) {
  body .swal2-container .swal2-toast .swal2-title {
    font-size: 0.75rem;
  }
}